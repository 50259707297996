import { Outlet } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';

import './skeleton.scss';

import HeaderComponent from './header/HeaderComponent';
import { footerNavsList } from '../../_mock/data';

const { Footer, Content } = Layout;

export default function Skeleton() {

  return (
    <Layout className="private-layout">

      <HeaderComponent />

      <Content style={{ minHeight: 'calc(100vh - 170px)', display: 'flex' }}>
        <Outlet />
      </Content>

      <Footer className='footer-container'>
        <span>© Action Clip 2022. All Rights Reserved.</span>
        <div className="nav-block">
          <Row gutter={[16, { xs: 16, sm: 0 }]}>
            {footerNavsList.map(item => (
              <Col className="gutter-row" key={item.name} xs={12} sm={6}>
                <a className='nav-item' href={item.link} target="_blank" rel="noopener noreferrer"> {item.name}</a>
              </Col>))
            }
          </Row>
        </div>
      </Footer>
    </Layout >
  )
}