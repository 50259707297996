import { AxiosResponse } from 'axios'
import API from './api'

import ApiResponse from './ApiResponse';
import { UsersProfile } from './auth';

export interface ChangePasswordDto {
  oldPassword: string;
  newPassword: string;
}
export interface ViewingUrlDto {
  viewingUrl: string;
}

export interface StaticValues {
  stream: {
    type: StaticValuesItem[],
    status: StaticValuesItem[],
    statusGroup: StaticValuesItem[]
  }
}

export interface StaticValuesItem {
  text?: string;
  value?: string;
  visible?: boolean;
}




export async function fetchUsersProfile(): Promise<ApiResponse<UsersProfile>> {
  try {
    const userProfile: AxiosResponse<UsersProfile> = await API.get('/me');

    return {
      success: true,
      data: userProfile.data,
      error: null,
    };
  } catch (error) {
    console.log('error', error);
    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error.response.data.error.properties[0].messages[0],
        name: 'Something went wrong',
      },
    };
  }
}


export async function changePassword(
  changePasswordCredentials: ChangePasswordDto
): Promise<ApiResponse<null>> {
  try {
    await API.post(
      '/me/password/change',
      { ...changePasswordCredentials, newPasswordForConfirmation: changePasswordCredentials.newPassword }
    );

    return {
      success: true,
      data: null,
      error: null,
    };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error?.response?.data?.error || error.message,
        name: 'Forgot password',
      },
    };
  }
}

export async function fetchViewingUrl(): Promise<ApiResponse<ViewingUrlDto>> {
  try {
    const viewingUrl: AxiosResponse<ViewingUrlDto> = await API.get('/viewing-url');

    return {
      success: true,
      data: viewingUrl.data,
      error: null,
    };
  } catch (error) {
    console.log('error', error);
    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: 'Cannot receive viewing url',
        name: 'Server request',
      },
    };
  }
}


export async function fetchFiltersValues(): Promise<ApiResponse<StaticValues>> {
  try {
    const viewingUrl: AxiosResponse<StaticValues> = await API.get('/static-values');

    return {
      success: true,
      data: viewingUrl.data,
      error: null,
    };
  } catch (error) {
    console.log('error', error);
    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error.response.data.error.properties[0].messages[0],
        name: 'Server request',
      },
    };
  }
}