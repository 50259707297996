import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// hooks
import { useAppStateValue } from '../state/StateContext';

import { Loader } from '../components/Loader';
import Login from '../pages/public/login/Login';
import PublicPages from '../pages/public/PublicPages';


// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const [{ isUserLogged, isStateInitialized }] = useAppStateValue();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isStateInitialized) {
    return <Loader />;
  }

  if (!isUserLogged) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <PublicPages><Login /></PublicPages>;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
