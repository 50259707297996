import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import logo from '../../assets/Logo.png';
import logoBlack from '../../assets/LogoBlack.png';
import siderBackground from '../../assets/publicPages/sider-background.svg';
import './publicPages.scss'

const { Footer, Sider, Content, Header } = Layout;

type Props = {
  children?: ReactNode;
};

export default function PublicPages({ children }: Props) {
  return (
    <Layout className="public-page-container">

      <Sider className='sider-container' width={463}>
        <img src={logo} alt="ac_logo" className='logo' />
        <div className="sider-content">
          <p className='sider-description'>Action Clip is the perfect video app for youth sport - now with streaming!</p>
          <img src={siderBackground} alt="siderBackground" />
        </div>
      </Sider>

      <Layout>
        <Header className='header-container'>
          <img src={logoBlack} alt="ac_logo" style={{ width: 120 }} />
        </Header>

        <Content>
          {children ?? <Outlet />}
        </Content>

        <Footer className='footer-container'>
          © Action Clip 2022. All Rights Reserved.
        </Footer>
      </Layout>
    </Layout>
  );
}
