// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/account';
const ROOTS_ERROR = '/error';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  registration: path(ROOTS_AUTH, '/registration'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  confirmForgotPassword: path(ROOTS_AUTH, '/confirm-forgot-password'),
};

export const PATH_PAGE = {
  // page404: '/404',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  streams: path(ROOTS_DASHBOARD, '/streams'),
  payment: {
    root: path(ROOTS_DASHBOARD, '/payment'),
    paymentSuccessful: path(ROOTS_DASHBOARD, '/payment/successful'),
  },
  settings: path(ROOTS_DASHBOARD, '/settings'),
  archiveStream: (id: string) => path(ROOTS_DASHBOARD, `/archive-stream/${id}`),
};

export const PATH_ERROR = {
  root: ROOTS_ERROR,
  404: path(ROOTS_ERROR, '/404'),
  403: path(ROOTS_ERROR, '/403'),
  500: path(ROOTS_ERROR, '/500'),
};

