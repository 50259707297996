
import { useEffect, useState } from 'react';
import { notification } from 'antd';

import { useAppStateValue } from '../state/StateContext';
import { fetchUsersProfile } from '../api/users';

import Router from '../routes';
import { Loader } from '../components/Loader';

export default function StreamOfficeApp() {
  const [, dispatch] = useAppStateValue();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validateSession = async () => {
    try {
      setIsLoading(true)

      if (localStorage.getItem('access_token')) {

        const usersProfile = await fetchUsersProfile();

        if (usersProfile.success && usersProfile.data) {
          const user = usersProfile.data;

          dispatch({ type: 'updateState', value: { isUserLogged: true, user, isStateInitialized: true } });
        }
        else if (usersProfile.error) {
          notification.error({
            message: usersProfile.error.name,
            description: usersProfile.error.message,
          });

          dispatch({ type: 'updateState', value: { isUserLogged: false, isStateInitialized: true } });
          console.log(usersProfile.error);
        }
      }
      else {
        dispatch({ type: 'updateState', value: { isUserLogged: false, isStateInitialized: true } });
      }


    } catch {
      console.log('Coach has to be authorized');
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    validateSession();
  }, []);


  if (isLoading) return <Loader />
  return <Router />;
}
