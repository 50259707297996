export const {
  REACT_APP_ENV: environment = 'dev',
  REACT_APP_API_URL: urlApiServer = '',
  REACT_APP_STRIPE_PUB_KEY: stripeKey = '',
} = process.env;

const config = {
  urlApiServer,
  environment,
  stripeKey,
  demoAccountName: 'APPLE_DEMO_ACCOUNT'
};

console.log('Environment', environment);

export default config;
