const acTermsUrl = 'https://actionclip.video/terms/'
const acPrivacyUrl = 'https://actionclip.video/privacy/'

var validatePassword = /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9!@#$%^&*a-zA-Z]{8,}/;

const footerNavsList = [
    {
        name: 'Pricing',
        link: 'https://actionclip.video/#subscription'
    },
    {
        name: 'Tutorials',
        link: 'https://actionclip.video/stream/howto/'
    },
    {
        name: 'FAQ',
        link: 'https://actionclip.video/stream/faqs/'
    },
    {
        name: 'Contacts',
        link: 'https://actionclip.video/#contacts'
    }
]

export { acTermsUrl, acPrivacyUrl, validatePassword, footerNavsList }