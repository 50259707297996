import axios from 'axios';
import jwt_decode from "jwt-decode";
import config from './config'
import { checkAuth } from './auth'
import LocalStorageHelpers from '../helpers/LocalStorageHelpers';
import { PATH_AUTH, PATH_ERROR } from '../routes/paths';

const api = axios.create({
  withCredentials: false,
  baseURL: config.urlApiServer,
});

api.interceptors.request.use(async (config) => {
  const access_token = localStorage.getItem('access_token')

  if (access_token) {
    const expiresIn = localStorage.getItem('expires_in') as string;
    const expiresInRefresh = localStorage.getItem('expires_in_refresh') as string;

    if (+expiresInRefresh * 1000 > Date.now()) {

      if (+expiresIn * 1000 < Date.now()) {
        const refresh_token = localStorage.getItem('refresh_token') as string;

        let response = await checkAuth(refresh_token);

        if (response.success && response.data) {
          const { data: newToken } = response;

          var decoded: any = jwt_decode(newToken.accessToken);
          var decodedRefresh: any = jwt_decode(newToken.refreshToken);

          localStorage.setItem('access_token', newToken.accessToken)
          localStorage.setItem('refresh_token', newToken.refreshToken)
          localStorage.setItem('expires_in', decoded.exp)
          localStorage.setItem('expires_in_refresh', decodedRefresh.exp)

        } else {
          console.log(response.error);
          // notification.error({
          //   message: response.error?.name,
          //   description: response.error?.message,
          // });
          LocalStorageHelpers.clearAllLocalStorage()
          window.location.href = PATH_AUTH.login;
        }
      }
    } else {
      LocalStorageHelpers.clearAllLocalStorage()
      window.location.href = PATH_AUTH.login;
    }
    // @ts-ignore
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
    return config;
  }
  else {
    LocalStorageHelpers.clearAllLocalStorage()
    delete api.defaults.headers.common.Authorization;
    window.location.href = PATH_AUTH.login;
  }
})


api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      LocalStorageHelpers.clearAllLocalStorage()
      // return window.location.href = PATH_AUTH.login
    }

    if (error.response.status === 500) window.location.href = PATH_ERROR[500]

    return Promise.reject(error);
  }
);

export default api