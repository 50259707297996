import { useState } from 'react';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import { Form, Input, Button, notification } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';

import { useAppStateValue } from '../../../state/StateContext';
import { signIn } from '../../../api/auth';
import { fetchUsersProfile } from '../../../api/users';

import './login.css';
import { PATH_AUTH } from '../../../routes/paths';
import { ConformCodeForm } from '../registration/ConformCodeForm';

function Login() {
  const [, dispatch] = useAppStateValue();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [userCredentials, setUserCredentials] = useState<{
    email: string;
    password: string;
  }>({ email: 'unknown', password: 'unknown' });

  const onFinish = async (values: Store) => {
    setIsLoading(true)
    const response = await signIn({
      email: values.email,
      password: values.password,
    });
    if (response.success && response.data) {
      const { data } = response;

      var decodedAccessToken: any = jwt_decode(data.accessToken);
      var decodedRefreshToken: any = jwt_decode(data.refreshToken);

      localStorage.setItem('access_token', data.accessToken)
      localStorage.setItem('refresh_token', data.refreshToken)
      localStorage.setItem('expires_in', decodedAccessToken.exp)
      localStorage.setItem('expires_in_refresh', decodedRefreshToken.exp)

      const usersProfile = await fetchUsersProfile();

      if (usersProfile.success && usersProfile.data) {
        const user = usersProfile.data;

        // TODO: Add user data to the global state if needed
        dispatch({ type: 'updateState', value: { isUserLogged: true, user } });
      }
      else if (usersProfile.error) {
        notification.error({
          message: usersProfile.error.name,
          description: usersProfile.error.message,
        });

        console.log(usersProfile.error);
      }
    }
    else if (response.error) {
      if (response.error.errorName === 'status') {
        setUserCredentials({
          email: values.email,
          password: values.password,
        })
      }

      notification.error({
        message: response.error.name,
        description: response.error.message,
      });

      console.log('Login failed');
      console.log(response.error);
    }
    setIsLoading(false)
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setIsLoading(false)
  };

  return (
    <div className="login-component">
      {userCredentials.email === 'unknown' ?
        <Form
          name="loginForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="login-form"
          layout={'vertical'}
          validateTrigger={'onSubmit'}
        >

          <h1 className='login-form-title'>Sign In</h1>

          <Form.Item
            name="email"
            required={false}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your e-mail',
              },
            ]}
            label="E-mail"
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Enter your e-mail"
              autoCapitalize="off"
            />
          </Form.Item>

          <Form.Item
            name="password"
            required={false}
            rules={[
              { required: true, message: 'Please input your password', min: 8 },
            ]}
            label="Password"
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Enter password"
            />
          </Form.Item>

          <div className='forgot-password-block'>
            <Link to={PATH_AUTH.forgotPassword} >Forgot password?</Link>
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-button" loading={isLoading}>
              Sign In
            </Button>
          </Form.Item>

          <div className="login-note">

            Not registered yet?
            <Link to={PATH_AUTH.registration}> Create an Account</Link>

          </div>
        </Form >
        :
        <ConformCodeForm email={userCredentials.email} password={userCredentials.password} />
      }
    </div>
  );
}

export default Login;
