import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import { Form, Input, Button, notification } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';

import { useAppStateValue } from '../../../state/StateContext';

import { signIn, confirmSignUp, resendConfirmation } from '../../../api/auth';
import { fetchUsersProfile } from '../../../api/users';
import { PATH_AUTH } from '../../../routes/paths';

import '../login/login.css';



export const ConformCodeForm = ({ email, password }: {
  email: string;
  password: string;
}): JSX.Element => {
  const [count, setCount] = useState<string | null>(null);
  const [, dispatch] = useAppStateValue();
  const [userCredentials] = useState({ email, password });


  useEffect(() => {
    countdown()
  }, [])


  const signInAfterConform = async (values: Store) => {

    const response = await signIn({
      email: values.email,
      password: values.password,
    });

    if (response.success && response.data) {
      const { data } = response;

      var decodedAccessToken: any = jwt_decode(data.accessToken);
      var decodedRefreshToken: any = jwt_decode(data.refreshToken);

      localStorage.setItem('access_token', data.accessToken)
      localStorage.setItem('refresh_token', data.refreshToken)
      localStorage.setItem('expires_in', decodedAccessToken.exp)
      localStorage.setItem('expires_in_refresh', decodedRefreshToken.exp)

      const usersProfile = await fetchUsersProfile();

      if (usersProfile.success && usersProfile.data) {
        const user = usersProfile.data;

        dispatch({ type: 'updateState', value: { isUserLogged: true, user } });
      }
      else if (usersProfile.error) {
        notification.error({
          message: usersProfile.error.name,
          description: usersProfile.error.message,
        });

        console.log(usersProfile.error);
      }
    }
    else if (response.error) {
      notification.error({
        message: response.error.name,
        description: response.error.message,
      });

      console.log(response.error);
    }
  };

  const conformCode = async (values: Store) => {
    const response = await confirmSignUp(userCredentials.email, +values.code);

    if (response.success) {
      signInAfterConform(userCredentials)
    } else if (response.error) {
      notification.error({
        message: response.error.name,
        description: response.error.message,
      });

      console.log(response.error);
    }
  };

  const resendConfirmationCode = async (email: string) => {
    const response = await resendConfirmation(email);

    if (response.success) {
    } else if (response.error) {
      notification.error({
        message: response.error.name,
        description: response.error.message,
      });

      console.log(response.error);
    }
  };

  function countdown() {
    var seconds = 60;
    function tick() {
      seconds--;

      let count = "00:" + (seconds < 10 ? "0" : "") + String(seconds);
      if (seconds > 0) {
        setTimeout(tick, 1000);
        setCount(count)
      } else {
        setCount(null)
      }
    }
    tick();
  }

  return (
    <Form
      name="conformCodeForm"
      onFinish={conformCode}
      className="login-form"
      layout={'vertical'}
    >
      <h1 className='login-form-title' style={{ marginBottom: 8 }}>Please check your e-mail!</h1>

      <p className='login-form-description'>To confirm the email address, please enter confirmation code</p>


      <Form.Item
        name="code"
        required={false}
        rules={[
          {
            required: true,
            message: 'Please input your confirmation code',
          },
        ]}
        label="Confirmation code"
      >
        <Input
          prefix={<SafetyOutlined className="site-form-item-icon" />}
          placeholder="Enter confirmation code"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-button">
          Validate
        </Button>
      </Form.Item>

      <div className="login-note">
        Didn’t receive the e-mail?
        {count ?
          <span style={{ color: '#FF8331', marginLeft: 4, lineHeight: '150%' }}>{count}</span>
          :
          <Button type="link" onClick={() => {
            resendConfirmationCode(userCredentials.email)
            countdown()
          }} style={{ padding: '0 5px', height: 'auto' }}>Resend code</Button>
        }
      </div>

      <div className="login-note">
        Go back to&nbsp;
        <Link to={PATH_AUTH.login}>Sign In</Link>
      </div>
    </Form >
  );
};
